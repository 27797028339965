<template>
  <Banner/>
  <Service/>
  <About/>
  <WorkProcess/>
  <RecentWork/>
  <Research/>
  <CeoMessage/>
  <Team/>
  <Partners/>
</template>

<script>
  import {Banner,Service,About,WorkProcess,RecentWork,Research,CeoMessage,Team,Partners} from '@/components'
  export default {
    name: 'Home',
    components: {
      Banner,Service,About,WorkProcess,RecentWork,Research,CeoMessage,Team,Partners
    }
  }
</script>
